@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Orbitron:wght@600&display=swap");
* {
  transition: color 0.2s ease-in, background-color 0.2s ease-in;
}
body {
  margin: 0;
  background-color: rgb(0, 0, 0);
  font-family: "Orbitron", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h4 {
  font-family: "Orbitron", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
p {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
