@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Orbitron:wght@600&display=swap);
* {
  transition: color 0.2s ease-in, background-color 0.2s ease-in;
}
body {
  margin: 0;
  background-color: rgb(0, 0, 0);
  font-family: "Orbitron", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h4 {
  font-family: "Orbitron", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
p {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Container_Container__2TVjX {
  overflow-x: hidden;
}

.Container_Container__2TVjX .Container_overlay__31LRZ {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.Container_Container__2TVjX .Container_header__rQnB7,
.Container_Container__2TVjX .Container_footer__2ZeRG {
  width: 100vw;
  height: 5rem;
  color: white;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Container_Container__2TVjX .Container_header__rQnB7 {
  top: 0;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(black, rgba(0, 0, 0, 0));
}

.Container_Container__2TVjX .Container_header__rQnB7 > * {
  position: relative;
  left: 10vw;
  text-transform: uppercase;
}

.Container_Container__2TVjX .Container_header__rQnB7 :first-child {
  margin-top: 7vh;
}

.Container_Container__2TVjX .Container_header__rQnB7 :nth-child(2) {
  margin: 0;
  font-weight: 500;
}

.Container_Container__2TVjX .Container_footer__2ZeRG {
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0) 20%, black);
  padding: 1.5rem 0 0.5rem;
  stroke: none;
}

.Container_Container__2TVjX .Container_footer__2ZeRG ul :nth-child(4) {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.Container_Container__2TVjX .Container_socials__3bxxF {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  margin: 0 10vw;
}

.Container_Container__2TVjX .Container_socials__3bxxF svg {
  height: 40px;
  width: 40px;
  fill: #888;
}

.Container_Container__2TVjX .Container_socials__3bxxF svg:hover {
  fill: #ccc;
}

.Container_Container__2TVjX .Container_menu__3981j {
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 20%;
  left: 30px;
  opacity: 0;
  pointer-events: auto;
}

@media (max-width: 600px) {
  .Container_Container__2TVjX .Container_menu__3981j {
    display: none;
  }
}

.Container_Container__2TVjX .Container_menu__3981j button {
  display: flex;
  color: #888;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  border: none;
  margin: 0;
  text-decoration: none;
}

.Container_Container__2TVjX .Container_menu__3981j button:hover {
  color: #eee;
}

.Container_Container__2TVjX .Container_menu__3981j button:hover .Container_icon__1WMR6 {
  background-color: #ccc;
}

.Container_Container__2TVjX .Container_menu__3981j .Container_icon__1WMR6 {
  height: 30px;
  width: 3px;
  margin: 8px;
  background-color: #444;
}
/*# sourceMappingURL=Container.module.css.map */
.Slide_Slide__1AuhT {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.Slide_Slide__1AuhT video {
  height: 90vh;
  width: auto;
  position: absolute;
  top: 5vh;
  z-index: -1;
}

.Slide_Slide__1AuhT .Slide_overlay__2psjk {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.Slide_Slide__1AuhT .Slide_text__1JaW- {
  color: white;
  font-size: 70px;
  position: absolute;
  top: 50%;
  left: 30%;
}
/*# sourceMappingURL=Slide.module.css.map */
.MenuButton_MenuButton__2Bq6Y {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 5vw 2rem 0;
  cursor: pointer;
}

.MenuButton_MenuButton__2Bq6Y:hover .MenuButton_image__2uNiS {
  border: 1px solid #ccc;
}

.MenuButton_MenuButton__2Bq6Y:hover .MenuButton_text__1Mee9 {
  color: #eee;
}

.MenuButton_MenuButton__2Bq6Y .MenuButton_image__2uNiS {
  width: 120px;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: border-box;
  background-position: center;
  border: 1px solid #888;
  border-radius: 10px;
}

.MenuButton_MenuButton__2Bq6Y .MenuButton_text__1Mee9 {
  color: #aaa;
  margin: 0 1.5rem;
}
/*# sourceMappingURL=MenuButton.module.css.map */
.Slider_Slider__1cBTN {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.Slider_Slider__1cBTN .Slider_slideContainer__3r8nh {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(-20%, 0);
          transform: translate(-20%, 0);
  overflow: hidden;
}

@media (max-width: 600px) {
  .Slider_Slider__1cBTN .Slider_slideContainer__3r8nh {
    opacity: 0;
  }
}

.Slider_Slider__1cBTN .Slider_overlay__123VZ {
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(0, 0, 0, 0) 40%, black 80%);
  position: absolute;
  top: 0;
  left: 0;
}

.Slider_Slider__1cBTN .Slider_divider__148SU {
  background: linear-gradient(250deg, black 20%, rgba(0, 0, 0, 0) 70%);
  height: 100vh;
  width: 100vw;
  position: absolute;
  right: 0;
  top: 0;
}

.Slider_Slider__1cBTN .Slider_menuContainer__TtnPv {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}

.Slider_Slider__1cBTN .Slider_visible__3g8GR {
  -webkit-animation-name: Slider_fade__2kG9o;
          animation-name: Slider_fade__2kG9o;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes Slider_fade__2kG9o {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Slider_fade__2kG9o {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*# sourceMappingURL=Slider.module.css.map */
.Page_Page__2lhdg {
  margin: 0 15vw 0 20vw;
  padding: 3rem 0;
}

@media (max-width: 600px) {
  .Page_Page__2lhdg {
    margin: 0 5vw 0 5vw;
  }
}

.Page_Page__2lhdg .Page_title__5VZCI {
  color: #fff;
  text-align: left;
  margin-left: -10vw;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .Page_Page__2lhdg .Page_title__5VZCI {
    margin-left: 0;
  }
}

.Page_Page__2lhdg .Page_background__1GyFo {
  display: -ms-grid;
  display: grid;
}

.Page_Page__2lhdg .Page_grid__3nees {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  margin-top: 2rem;
  grid-gap: 1rem;
  gap: 1rem;
}

@media (max-width: 768px) {
  .Page_Page__2lhdg .Page_grid__3nees {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}
/*# sourceMappingURL=Page.module.css.map */
.LargeComponent_LargeComponent__3yg5p {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .LargeComponent_LargeComponent__3yg5p {
    flex-direction: column;
  }
}

.LargeComponent_LargeComponent__3yg5p .LargeComponent_description__1tFYR {
  color: #aaa;
  margin-left: 3rem;
  text-align: left;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .LargeComponent_LargeComponent__3yg5p .LargeComponent_description__1tFYR {
    margin: 0;
  }
}

@media (max-width: 600px) {
  .LargeComponent_LargeComponent__3yg5p .LargeComponent_description__1tFYR {
    font-size: 1rem;
  }
}

.LargeComponent_LargeComponent__3yg5p video {
  width: 70%;
  height: auto;
  max-height: 500px;
}

@media (max-width: 768px) {
  .LargeComponent_LargeComponent__3yg5p video {
    width: 100%;
  }
}
/*# sourceMappingURL=LargeComponent.module.css.map */
.SmallComponent_SmallComponent__23HYx .SmallComponent_description__2yCPq {
  color: #aaa;
  text-align: left;
  margin: 0 1rem;
  font-size: 0.8rem;
}

@media (max-width: 600px) {
  .SmallComponent_SmallComponent__23HYx .SmallComponent_description__2yCPq {
    font-size: 1rem;
  }
}

.SmallComponent_SmallComponent__23HYx video {
  width: 100%;
  height: auto;
  max-height: 300px;
}
/*# sourceMappingURL=SmallComponent.module.css.map */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

